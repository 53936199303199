import React from "react";
import { Box } from "@material-ui/core";

export default function NotFound() {
    if (typeof window !== "undefined") {
        window.location = "/";
    }

    return <Box bgcolor="#F5F5F0" height="100vh" overflow="auto" />;
}
